import React from 'react'

import { scrumIntro, learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, estimated, closing } from './AL00000Sprint00'

import { toolsOfTheTradeLink } from '../Activities/ToolsOfTheTrade'
import { externalLink, oreillyPlaylistSC } from '../DataAndAPIs/Links'
import { getModuleDescription } from '../DataAndAPIs/ClassSections'

import { capstoneSprint1Link } from '../Activities/ClassProduct'
import { capacityCommittedEffortDeliveredLink } from '../Activities/CapacityCommittedEffortDelivered'
import { gettingToKnowEachOtherLink } from '../Activities/WebGettingToKnowEachOther'

export const al49200Sprint01 = (classSectionID, sprint) => { 
	return ( <div>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>
		<p><em>Welcome to Software Systems Capstone!</em> By this point in your professional software development journey, I'm 
		sure you are confident in writing moderately complicated software programs. You’ve learned about structured programming 
		techniques and how to break complicated programs down into more manageable pieces by using functions and even web
		services. You understand how Object-Oriented Programming can help you develop more complex applications by utilizing 
		encapsulation, inheritance, and polymorphism. You understand the key elements of Software Engineering including 
		application architecture, design patterns, and software development life cycles (SDLCs)  
		like Agile and Scrum.</p>

		<p>Now it’s time to demonstrate those skills in an real-world environment. Any new real-world Agile software development 
		engagement would start with your assignment to a scrum product team. So we will start the semester by reviewing our 
		product and team assignments. Then let's get to work buiding something that we can be proud of creating.</p>

		{scrumIntro(classSectionID, sprint)}

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Effectively participate in a blended classroom environment</li>
			<li>Effectively lead and participate in the creation on your product focused scrum team</li>
			<li>Describe and utilize the key elements of agile development and scrum</li>
			<li>Implement Agile Software Development Life Cycle Roles, Activities, and Artifacts for your scrum team</li>
		</ul>

		{list49200Sprint01(sprint)}
		{closing(sprint)}
	</div> ) 
}

const lectureRecordingPlaylist = () => { return externalLink('lecture', 'https://lewisu.hosted.panopto.com/Panopto/Pages/Viewer.aspx?pid=50ad011a-766e-43e7-8ec4-ae2301373d46')}

export const list49200Sprint01 = (sprint) => {
	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
		<li>Review our sprint {sprint} planning {lectureRecordingPlaylist()}</li>
			{standardActivities(sprint, capstoneSprint1Link())}
			<li>Within {oreillyPlaylistSC()} read “Essential Scrum” from the beginning through chapter 3</li>
			<li>Complete {gettingToKnowEachOtherLink()}</li>
			{initialPost(sprint)}
			<li>Within {oreillyPlaylistSC()} scan “Essential Scrum” chapters 4 through 12</li>
			<li>Complete {toolsOfTheTradeLink()}{estimated('2 hours')}</li>

			<li>Complete {capacityCommittedEffortDeliveredLink()}</li>
			{standardActivitiesClosing(sprint, capstoneSprint1Link(), '6 hours')}
		</ol>
	</div> )
}

// Certified for SP25 on January 20, 2025





