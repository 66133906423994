import React from 'react'

import { getClassTitle, getModuleDescriptionByClassSectionID } from '../DataAndAPIs/ClassSections'
import { CommandLine, internalLink } from '../DataAndAPIs/Links'
import { scrumIntro, learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, closing, estimated } from './AL00000Sprint00'

import { introductionsWebsiteLink } from '../Activities/IntroductionsWebsite'
import { toolsOfTheTradeLink } from '../Activities/ToolsOfTheTrade'
import { programmingTogether } from '../DataAndAPIs/Links'

export const al20000Sprint01 = (classSectionID, sprint) => {
	return ( <div>
		<h4>{getClassTitle()}</h4>
		<h5>Sprint {sprint}: {getModuleDescriptionByClassSectionID(classSectionID, sprint-1)}</h5>

		<p>Welcome to Introduction to Computer Science! In this course we will be exploring the field of computer 
		science. The course will cover software engineering, computer architecture, data organization, 
		networking, and security. We will also explore algorithms, abstractions, and computational 
		problem-solving.</p> 

		{scrumIntro(classSectionID, sprint)}

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Participate in a blended classroom environment and as part of a scrum team</li>
			<li>Utilize HTML to create a website</li>
			<li>Utilize important developer tools like HTML and GitHub to create, host, and update software applications</li>
			<li>Effectively utilize file systems and files including zip files</li>
			<li>Use both graphical and command line tools</li>
		</ul>

		{list20000Sprint01(sprint)}
		{closing(sprint)}
	</div> )
}

export const list20000Sprint01 = (sprint) => {
	const daleChapter11Lecture = () => { return internalLink('chapter 11 lecture', '/activity-dale/11') }
	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, introductionsWebsiteLink())}
			<li>Get to know your scrum team by setting up a team Discord server</li>

			{initialPost(sprint)}

			<li>Read “Computer Science Illuminated” chapter 11 File Systems and Directories and review the associated {daleChapter11Lecture()}</li>
			<li>Review {CommandLine()}</li>
			<li>Complete {toolsOfTheTradeLink()}{estimated('2 hours')}</li>
			<li>Preview “Hello World Plus for the Web” from our {programmingTogether()} video tutorials</li>

			{standardActivitiesClosing(sprint, introductionsWebsiteLink(), '5 hours')}
		</ol>
	</div> )
}

// Certified for SP25 on January 11, 2025
// Todo: Consider removing the standard activity of adding a representative picture of your self to blackboard from all
//    sprint 1 class sections. Also, consider removing the related Lab 1 question. 
