import React from 'react'

import { makeNavigationLink } from './ActivityLink'
import { HelloWorldWebPart1Link } from './HelloWorld'
import { GitHub, externalLink } from '../DataAndAPIs/Links'

const renderIntroductionsWebsite = () => {
	const introductionsWebsiteTemplate = () => { return externalLink('Introductions Website Template', 'https://github.com/EricPogueExampleCode-Web/introductions-2024')}
	return ( <div><br />
		<h3>{introductionsWebsite.name}</h3>
		<h5>Summary</h5>
		<p>Create an Introductions Website and host the website using GitHub Pages. Complete the following steps to 
		create, host, and update host your own introductions website.</p>
		<h5>Prerequisites</h5>
		<p>In order to complete this activity you will need a {GitHub()} account, a web browser, and an interest in HyperText Markup 
		Language (HTML) and Cascading Style Sheets (CSS).</p>
					
		<h5>Requirements</h5>
		<ol type='n'>
			<li>Sign into your {GitHub()} account</li>
			<li>Create a website named <em>“me”</em> by following the instructions at {introductionsWebsiteTemplate()}</li>
			<li>Update your Introductions Website so that it reflects your information</li>
			<li>Fix the “Fun Fact” section to match the CSS styling of the other sections</li>
			<li>Add an unstyled “Last Updated on dd/mm/yyyy” line at the bottom of the page that includes the date the website was last updated</li>
		</ol>
		<h5>Next Steps</h5>
		<p>Do you know what would happen if you added a Theme? Want to give it a try next time you create a website? 
		If you are having difficulties, you may find the {HelloWorldWebPart1Link()} tutorial valuable.</p>
		<p></p>
	</div> )
}

export const introductionsWebsite = { 'name':'Building an Introductions Website with GitHub Pages', 'route':'introductions-website', 'render': renderIntroductionsWebsite }
export const introductionsWebsiteLink = () => { return makeNavigationLink(introductionsWebsite) }
