import React from 'react'

import { learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, closing, estimated } from './AL00000Sprint00'
import { oreillyPlaylistSE, HTMLW3C, CSSW3C, JavaScriptW3C, RegularExpressionsW3C, internalLink } from '../DataAndAPIs/Links'

import { assignmentPortfolioLink } from '../Activities/WebAssignmentPortfolio'
import { azureWebsiteLink } from '../Activities/AzureWebsite'
import { webResumeLink } from '../Activities/WebResume'
import { webDiceRollerLink } from '../Activities/WebDiceRoller'
import { getModuleDescription } from '../DataAndAPIs/ClassSections'

const foxChapter2Lecture = () => internalLink('lecture', '/activity/fox-chapter-02')
const foxChapter6Lecture = () => { return internalLink('lecture', '/activity/fox-chapter-06')}

export const al44000Sprint02 = () => {
	const sprint = 2
	return ( <div>
		<h5>Sprint 2: {getModuleDescription(sprint-1)}</h5>
		
		<p><em>Welcome to sprint 2.</em> We covered a lot of material in sprint 1. However, we didn't get a chance to write 
		much code. This sprint get your text editors out. We are going to start practicing some of the concepts we learned.</p>

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Write simple HTML, CSS, Javascript, and Node.js applications and install them to Azure</li>
			<li>Understand simple Ruby programs using basic control structures and data structures</li>
			<li>Understand has SaaS applications are developed in JavaScript using AJAX and JSON</li>
			<li>Comfortably be able to utilize single-developer workflow with Git and GitHub</li>
		</ul>

		{list44000Sprint02(sprint)}
		{closing()}
	</div> )
}

export const list44000Sprint02 = (sprint) => {
	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, assignmentPortfolioLink())}
			<li>Read “Engineering Software as a Service” Chapter 2 and review our associated {foxChapter2Lecture()}</li>
			<li>Complete {azureWebsiteLink()}{estimated('2 hours')}</li>
			<li>Review the Potpourri web application from our example code GitHub repository</li>
			<li>Within {oreillyPlaylistSE()} scan “Learning Web Development” Preface through Chapter 4</li>
			<li>Implement your {webResumeLink()} while completing the W3Schools {HTMLW3C()} and {CSSW3C()} tutorials{estimated('6 hours')}</li>
			{initialPost(sprint)}
			<li>Complete {webDiceRollerLink()}</li>
			<li>Read “Engineering Software as a Service” chapter 6 plus A.6 and A.7 and review our chapter 6 {foxChapter6Lecture()}</li>
			<li>Complete {JavaScriptW3C()} tutorial up to {RegularExpressionsW3C()}</li>
			{standardActivitiesClosing(sprint, assignmentPortfolioLink(), '2 hours')}
		</ol>
	</div> )
}

// Certified for FA24 on September 8, 2024
// Todo: Strongly consider replacing something in sprint 2 with “Lions, Tiger, and Bears...” related assignment. 


// What to do with the following that was in sprint 1:
// 			<li>Describe the benefits of application architectures including software as a service and cloud computing</li>

// Todo: Figure out what happended to testing. It is in Quiz 6 but I don't see it in the content.

// Below are notes from the entire class and not just sprint 1. 

// Consider the following items for Software Engineering (04/14/24 EJP):
// 1 - Update all FinalProject links to ClassProduct links and unify Software Systems Capstone and Software Engineering (do it)
// 2 - Start Product work one sprint earlier in Software Engineering
// 3 - Assign team products earlier 
// 4 - Consider migrating books (that's going to be challenging)

// Todo: Consider adding funny video https://www.youtube.com/watch?v=Uo3cL4nrGOk

// Todo: Integrate Software Maintenance, Support, and Metrics into all appropriate classes.
// Todo: Consider doing the same thing for testing.
// Todo: Where should mediaatoz/software-engineering/* videos go? Are they already included?

// Review GitHub Organization tutorials:
// https://docs.github.com/en/organizations/collaborating-with-groups-in-organizations/creating-a-new-organization-from-scratch 
// https://www.youtube.com/watch?v=SUu6cmcC-3A
// https://developers.sap.com/tutorials/webide-github-creating-org.html 


// Todo: Add Support and Maintenance lecture video topics to Quiz 5. Are they already there?


// Consider adding back <li>Within {oreillyPlaylistSE()} scan “Essential Scrum” Chapters 1 and 2</li>
// Todo: Update to reflect Zac’s fix (see related announcement). 
// Todo: Move Whatever Happened to Ruby to earlier sprints.
// Todo: Consider moving  some of the Waterfall Project Management video and assignment from Sprint 5 to sprint 3.
// Todo: Consider moving something related to “Dogs, JavaScript & An API 🐶 Fetch, Promises & Async Await” from sprint 4 to sprint 3
// Todo: Review https://www.geeksforgeeks.org/egg-dropping-puzzle-dp-11/
// Todo: Identify where quiz answers originate from. 
// Todo: Fix the end of the Fox Preface video.
// Todo: Review https://docs.github.com/en/repositories/managing-your-repositorys-settings-and-features/managing-repository-settings/managing-teams-and-people-with-access-to-your-repository
// Todo: Consider adding W3C Validator VS code add-in to several classes. 

// Todo: Consider updating and adding the following Architecture videos back into the class:
//	import { architecturePart1, architecturePart2 } from '../DataAndAPIs/Links'
// <li>Complete {architecturePart1()}</li>
// <li>Scan {architecturePart2()}</li>
// Consider adding https://www.youtube.com/watch?v=A-H-xZ5ZXgo
// Todo: Consider adding back in Architecture lecture or removing SQL questions from quiz.
// Todo: Make sure that in sprints 7 & 8 that “individual” lab questions are identified. 
// Todo: Review Zac’s quiz and update quiz 7. 
// Todo: Consider '<li>Complete {AzureFunctionsWithVSCodeAndJavaScript()} and build your <em>personal</em> serverless function</li>'
// Todo: Consider '<li>Within {oreillyPlaylistSE()} search for “Azure Functions” and “Serverless Computing” and document the best resources</li>'
// Todo: Consider moving final project proposals to sprint 4. 
// Todo: Consider updating Lab 4 in that the spike doesn’t seem like a totally natural fit.  
// Todo: Move some of the Waterfall Project Management video and assignment from Sprint 5 to sprint 4.
// Todo: Add something to lab 4 to validate Dogs, JavaScript & An API 🐶 Fetch, Promises & Async Await.
// Todo: Move Final Project Proposal from sprint 5 to sprint 4.
// Todo: Verify the length of the videos and see if they are really 4 hours (per Nathan) and consider breaking them up between sprints.






